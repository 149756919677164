import { Injectable } from '@angular/core';

import { Item } from '../models/item';
import { ItemDetails } from '../models/item-details';

import { AngularFirestore } from '@angular/fire/firestore';
import { merge } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ItemService {
  constructor(
    private firestore: AngularFirestore) { }

  getItems(listId: string) {
    return this.firestore.collection('items', ref => ref.where('listId', '==', listId)).snapshotChanges();
  }

  createItem(item: Item) {
    return this.firestore.collection('items').add(item);
  }

  updateItem(item: Item) {
    this.firestore.doc('items/' + item.id).update(item);
  }

  deleteItem(item: Item) {
    this.firestore.doc('items/' + item.id).delete();
  }

  saveItemDetails(itemDetails: ItemDetails) {
    this.firestore.collection('itemDetails').doc(itemDetails.itemId)
    .set(
      {
        html: itemDetails.html,
        itemId: itemDetails.itemId
      }, {merge: true});
  }

  getItemDetails(itemId: string) {
    return this.firestore.collection('itemDetails', ref => ref.where('itemId', '==', itemId)).snapshotChanges();
  }
}
