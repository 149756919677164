import { Item } from '../items/models/item';

export class List {
    id: string;
    name: string;
    nameInsensitive: string;
    ownerId: number;
    isActive: boolean;
    createdDate: Date;
    isAdvanced: boolean;
    objectID: string;
}
