import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { List } from 'src/app/lists/list';
import { Item } from 'src/app/items/models/item';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private firestore: AngularFirestore) { }

  getLists(userId: string) {
    return this.firestore.collection('lists', ref => ref
      .where('ownerId', '==', userId))
      .snapshotChanges();
  }

  getListById(id: string) {
    return this.firestore.collection('lists', ref => ref
      .where('id', '==', id))
      .snapshotChanges();
  }

  fireQuery(userId: string, start, end) {
    return this.firestore.collection('lists', ref => ref.limit(4)
      .where('ownerId', '==', userId)
      .orderBy('nameInsensitive')
      .startAt(start)
      .endAt(end))
      .snapshotChanges();
  }

  createList(list: List) {
    return this.firestore.collection('lists').add(list);
  }

  updateListAdvanceTrigger(listId: string, isAdvanced: boolean) {
    this.firestore.doc('lists/' + listId).update({isAdvanced});
  }

  updateList(list: List): any {
     return this.firestore.doc('lists/' + list.id).update(list);
  }

  deleteList(listId: string, items: Item[]) {
    this.firestore.doc('lists/' + listId).delete();
    for (const item of items) {
      this.firestore.doc('items/' + item.id).delete();
    }
  }
}
