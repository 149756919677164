import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  public _listId: string;
  @Input()
  set listId(listId: string) {
    this._listId = listId;
  }
  options = environment.algoliaUsers;
  color = 'accent';
  disabled = false;
  isShared = false;
  constructor() { }

  ngOnInit() {
  }

  updateIsSharing(uid: string) {
    const test = uid; // update this to save.  also figure out how to display the sharing switch properly
  }
}
