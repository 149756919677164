import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

import { User } from '../../auth/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private firestore: AngularFirestore) { }

  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }

  createUser(user: User) {
    return this.firestore.collection('users').add(user);
  }

  updateUser(user: User) {
    this.firestore.doc('users/' + user.uid).update(user);
  }

  deleteUser(user: User) {
    this.firestore.doc('users/' + user.uid).delete();
  }
}
