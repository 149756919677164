import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../auth/user.model';

import { AuthService } from '../auth/services/auth.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-new-user-registration',
  templateUrl: './new-user-registration.component.html',
  styleUrls: ['./new-user-registration.component.scss']
})
export class NewUserRegistrationComponent implements OnInit {
  public user: User;
  public newUser: User;
  paperLines: number[];

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private router: Router) {
    this.paperLines = Array(4).fill(0).map((x, i) => {
      return i;
    });
  }

  ngOnInit() {
    this.auth.user$
      .subscribe((user: User) => {
        this.user = user;
        this.newUser = ({
          uid: this.user.uid,
          email: this.user.email,
          displayName: this.user.displayName,
          publicName: '',
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          phoneNumber: '',
          photoURL: this.user.photoURL !== '' ? this.user.photoURL : '',
          providerId: this.user.providerId,
          isPublic: false
        }) as User;
      });
  }


  updateUserRegistration() {
    this.newUser.isRegistered = true;
    this.userService.updateUser(this.newUser);
    this.router.navigate(['/lists']);
  }
}
