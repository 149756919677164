/**
 * The main component that renders single TabComponent
 * instances.
 */

import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    ViewChild,
    ComponentFactoryResolver,
    EventEmitter,
    Output,
    Input,
    AfterViewChecked
} from '@angular/core';

import { DynamicTabComponent } from '../tab/dynamic-tab.component';
import { DynamicTabsDirective } from '../dynamic-tabs.directive';
import { MatTabChangeEvent } from '@angular/material';

// Models
import { DynamicTabInfo } from '../dynamic-tab-Info.model';
import { List } from 'src/app/lists/list';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-dynamic-tabs',
    templateUrl: './dynamic-tabs.component.html',
    styleUrls: ['./dynamic-tabs.component.scss']
})
export class DynamicTabsComponent implements AfterViewChecked {
    dynamicTabs: DynamicTabComponent[] = [];

    dataId: any;

    @Input() allowDynamicTabClick: boolean;

    @Input() tabLimit: number;

    @Input() list: List;

    @Output() tabClicked = new EventEmitter<any>();

    @Output() dynamicTabClicked = new EventEmitter<DynamicTabInfo>();

    @Output() dynamicTabCount = new EventEmitter<number>();

    @ContentChildren(DynamicTabComponent) tabs: QueryList<DynamicTabComponent>;

    @ViewChild(DynamicTabsDirective) dynamicTabPlaceholder: DynamicTabsDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver) { }

    // contentChildren are set
    ngAfterViewChecked() {

        // get all active tabs
        const activeTabs = this.tabs.filter(tab => tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length === 0) {
            if (this.list) {
                const tab = this.tabs.filter(tab => tab.dataContext.id === this.list.id);
                this.selectTab(tab[0]);
            } else {
                this.selectTab(this.tabs.first);
            }
        }
    }

    openTab(title: string, template: any, data: any, isCloseable = false) {
        // fetch the view container reference from our anchor directive
        const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

        // Lets check if the feature has a limit on how many tabs can be open and if we've hit that limit.
        if (this.dynamicTabs.length < this.tabLimit) {
            // get a component factory for our TabComponent
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                DynamicTabComponent
            );

            // create a component instance
            const componentRef = viewContainerRef.createComponent(componentFactory);

            // set the according properties on our component instance
            const instance: DynamicTabComponent = componentRef.instance as DynamicTabComponent;
            instance.title = title;
            instance.template = template;
            instance.dataContext = data;
            instance.isCloseable = isCloseable;

            // remember the dynamic component for rendering the
            // tab navigation headers
            this.dynamicTabs.push(componentRef.instance as DynamicTabComponent);

            // set it active
            this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);

            this.dynamicTabCount.emit(this.dynamicTabs.length);
        }
    }

    selectTab(dynamicTab: DynamicTabComponent) {
        // deactivate all tabs
        this.tabs.toArray().forEach(tab => (tab.active = false));
        if (dynamicTab) {
            this.dynamicTabs.forEach(tab => (tab.active = false));
            // activate the tab the user has clicked on.
            dynamicTab.active = true;
            this.tabClicked.emit(dynamicTab.dataContext);
        }
    }

    isTabClicked($event) {
        const tabs = this.tabs.toArray();
        this.tabClicked.emit(tabs[$event.index].dataContext);
    }

    isDynamicTabClicked(dynamicTab: DynamicTabComponent) {
        if (this.allowDynamicTabClick) {
            const tabInfo = new DynamicTabInfo();
            tabInfo.title = dynamicTab.title;
            tabInfo.data = dynamicTab.dataContext;

            this.dynamicTabClicked.emit(tabInfo);
        }
        this.selectTab(dynamicTab);
    }

    closeTab(tab: DynamicTabComponent) {
        for (let i = 0; i < this.dynamicTabs.length; i++) {
            if (this.dynamicTabs[i] === tab) {
                // remove the tab from our array
                this.dynamicTabs.splice(i, 1);

                // destroy our dynamically created component again
                const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
                // let viewContainerRef = this.dynamicTabPlaceholder;
                viewContainerRef.remove(i);

                // set tab index to 1st one
                this.selectTab(this.tabs.first);

                // refresh data for the default tab if needed
                this.isTabClicked(tab);

                this.dynamicTabCount.emit(this.dynamicTabs.length);

                break;
            }
        }
    }

    closeActiveTab() {
        const activeTabs = this.dynamicTabs.filter(tab => tab.active);
        if (activeTabs.length > 0) {
            // close the 1st active tab (should only be one at a time)
            this.closeTab(activeTabs[0]);
        }
    }
}