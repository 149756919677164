import { Component, OnInit, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';

// Models
import { Item } from '../items/models/item';
import { List } from './list';
import { User } from '../auth/user.model';

// Services
import { ItemService } from '../items/services/item.service';
import { AuthService } from '../auth/services/auth.service';
import { ListService } from './services/list.service';

// Components
import { ConfirmModalComponent } from '../shared/components/confirm-modal/confirm-modal.component';
import { DynamicTabsComponent } from '../dynamic-tabs/tabs/dynamic-tabs.component';

// External
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ModalData } from '../shared/components/confirm-modal/modal-data';
import * as firebase from 'firebase';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  constructor(
    private itemService: ItemService,
    private listService: ListService,
    private auth: AuthService,
    private dialog: MatDialog) { }
  user: User;
  items: Item[] = [];
  lists: List[];
  list: List;
  newList: any;
  listId: string;
  listName: string;
  listAdvancedSetting: boolean;
  options = environment.algoliaLists;
  newListCreated = false;
  private unsubscribe: Subject<void> = new Subject();

  @ViewChildren(DynamicTabsComponent) dynamicTabsComponent: any;
  @ViewChildren('dynamicListTemplate') dynamicListTemplate: any;
  @ViewChild('item') itemField: ElementRef;

  ngOnInit() {
    this.auth.user$
      .subscribe(user => {
        this.user = user;
        this.getLists(user.uid);
      });
  }

  updatedList($event) {
    this.list = $event;
  }

  getItems(list: List): void {
    this.listName = list.name;
    this.listId = list.id;
    this.listAdvancedSetting = list.isAdvanced;
    this.itemService.getItems(list.id)
      .pipe(
        takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.items = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as Item;
        });
      });
  }

  getLists(userId: string): void {
    this.listService.getLists(userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.lists = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as List;
        });
      });
  }

  updateListsFromSearch(event = null) {
    if (event && !this.newListCreated) {
      this.lists = event.hits;
    }
  }

  onPressEnterNewList(event) {
    this.newList = {
      name: event,
      nameInsensitive: event.toLowerCase(),
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      isActive: true,
      isAdvanced: false,
      ownerId: this.user.uid
    };
    this.create(this.newList);
  }

  create(list: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'Confirm Create List',
      yesText: 'Yes',
      noText: 'Cancel',
      bodyText: 'Create' + ' ' + list.name + ' as a new list?'
    } as ModalData;

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.listService.createList(list)
          .then(() => {
            this.newListCreated = true;
            this.getLists(this.user.uid);
          });
      }
    });
  }
}
