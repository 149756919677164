import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListsComponent } from './lists/lists.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewUserRegistrationComponent } from './new-user-registration/new-user-registration.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  { path: '', redirectTo: 'lists', pathMatch: 'full' },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'login', component: LoginComponent },
  { path: 'lists', component: ListsComponent, canActivate: [AuthGuard] },
  { path: 'userProfile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'newUserRegistration', component: NewUserRegistrationComponent, canActivate: [AuthGuard] },
  { path: 'pageNotFound', component: PageNotFoundComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'pageNotFound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
