import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-algolia-list-search',
  templateUrl: './algolia-list-search.component.html',
  styleUrls: ['./algolia-list-search.component.scss']
})
export class AlgoliaListSearchComponent implements OnInit {
  @Output() searchedList = new EventEmitter<any>();
  @Output() createNewList = new EventEmitter<any>();
  options = environment.algoliaLists;
  color = 'accent';
  disabled = false;
  constructor() { }

  ngOnInit() {
  }

  onSearchChange({ results, state }: { results: {}, state: {} }) {
    this.searchedList.emit(results);
  }

  onPressEnterNewList(event) {
    if (event.key === 'Enter' && event.target.value !== '') {
      this.createNewList.emit(event.target.value);
    }
  }
}
