import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

import { ListService } from '../lists/services/list.service';
import { AuthService } from '../auth/services/auth.service';

import { User } from '../auth/user.model';
import { List } from '../lists/list';

import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {
  @Output() updateLists = new EventEmitter();
  @Output() addNewList = new EventEmitter();
  lists: List[];
  allLists: List[];
  user: User;
  searchterm: string;
  startAt = new Subject();
  endAt = new Subject();

  startobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  constructor(private listService: ListService, private auth: AuthService, private fireStore: AngularFirestore) {

  }

  ngOnInit() {
    this.auth.user$
      .subscribe(user => {
        this.user = user;
        this.getLists(user.uid);
        combineLatest(this.startobs, this.endobs).subscribe((value) => {
          this.firequery(user, value[0], value[1]);
        });
      });
  }

  search($event) {
    const userInput = $event.target.value.toLowerCase();
    if (userInput !== '') {
      this.startAt.next(userInput);
      this.endAt.next(userInput + '\uf8ff');
    } else {
      this.lists = this.allLists;
      this.updateLists.emit(this.lists);
    }
  }

  firequery(user: User, start, end) {
    return this.listService.fireQuery(user.uid, start, end)
    .subscribe(data => {
      this.lists = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as List;
      });
      this.updateLists.emit(this.lists);
    });
  }

  getLists(userId: string): void {
    this.listService.getLists(userId)
      .subscribe(data => {
        this.allLists = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as List;
        });
      });
  }

  onPressEnterNewList(event): void {
    if (event.key === 'Enter' && event.target.value !== '') {
      this.addNewList.emit(event.target.value);
    }
  }

    addNewListButtonClicked(): void {
      this.addNewList.emit(this.searchterm);
    }
}
