import { Component, OnInit, Input } from '@angular/core';

import { Item } from '../../models/item';

import { ItemService } from '../../services/item.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.scss']
})
export class ItemRowComponent implements OnInit {
  newItemName = '';
  public checkmarkOpacity: string;
  public itemNameTextDecoration: string;
  public itemNameOpacity: string;

  public _item: Item;
  @Input()
  set item(item: Item) {
    this._item = item;
    if (this._item) {
      this.getItemCheckedOffStyles(this._item.checkedOff);
    }
  }

  public _isNewItemRow: boolean;
  @Input()
  set isNewItemRow(isNewItemRow: boolean) {
    this._isNewItemRow = isNewItemRow;
  }

  public _listId: string;
  @Input()
  set listId(listId: string) {
    this._listId = listId;
  }

  constructor(
    private itemService: ItemService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  getItemCheckedOffStyles(checkedOff: boolean) {
    this.checkmarkOpacity = checkedOff ? '1' : '0.4';
    this.itemNameTextDecoration = checkedOff ? 'line-through' : 'none';
    this.itemNameOpacity = checkedOff ? '0.3' : '1';
  }

  onPressEnterNew(event) {
    if (event.key === 'Enter' && event.target.value !== '') {
      const newItem = {
        name: event.target.value,
        listId: this._listId,
        created_date: (new Date()).toUTCString(),
        note: '',
        checkedOff: false
      };
      this.create(newItem);
    }
  }

  create(item: any) {
    this.itemService.createItem(item);
    this.newItemName = '';
  }

  update(item: Item) {
    item.editedDate = (new Date()).toUTCString();
    this.itemService.updateItem(item);
    this.toastr.success('Item name has been updated');
  }

  deleteItem(item: Item) {
    this.itemService.deleteItem(item);
  }

  checkOff(item: Item) {
    item.checkedOff = !item.checkedOff;
    this.itemService.updateItem(item);
    if (item.checkedOff) {
      this.toastr.success(item.name + ' has been checked off the list');
    } else {
      this.toastr.info(item.name + ' has been un-checked');
    }
    this.getItemCheckedOffStyles(item.checkedOff);
  }
}
