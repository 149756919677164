import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpClientModule } from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { FirestoreSettingsToken} from '@angular/fire/firestore';

// Material UI
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ItemsComponent } from './items/items.component';
import { MessagesComponent } from './messages/messages.component';
import { ListsComponent } from './lists/lists.component';
import { DynamicTabsDirective } from './dynamic-tabs/dynamic-tabs.directive';
import { DynamicTabComponent } from './dynamic-tabs/tab/dynamic-tab.component';
import { DynamicTabsComponent } from './dynamic-tabs/tabs/dynamic-tabs.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';

// Services
import { CheckForUpdateService } from './shared/services/checkforupdate.service';

// Modules
import { MaterialModule } from './material/material.module';
import { ToastrModule } from 'ngx-toastr';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NewUserRegistrationComponent } from './new-user-registration/new-user-registration.component';
import { SharedModule } from './shared/shared.module';
import { ItemRowComponent } from './items/item-row/item-row/item-row.component';
import { ListSearchComponent } from './list-search/list-search.component';
import { QuillModule } from 'ngx-quill';
import { NgAisModule } from 'angular-instantsearch';
import { UserSearchComponent } from './user-search/user-search.component';
import { AlgoliaListSearchComponent } from './algolia-list-search/algolia-list-search.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [
    DynamicTabsDirective,
    DynamicTabComponent,
    DynamicTabsComponent,
    AppComponent,
    ItemsComponent,
    MessagesComponent,
    ListsComponent,
    LoginComponent,
    PageNotFoundComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavListComponent,
    UserProfileComponent,
    NewUserRegistrationComponent,
    ItemRowComponent,
    ListSearchComponent,
    UserSearchComponent,
    AlgoliaListSearchComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // Specify the ngx-auth-firebaseui library as an import
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig),
    ToastrModule.forRoot(),
    QuillModule,
    NgAisModule
  ],
  providers: [
    AngularFirestore,
    AuthGuard,
    CheckForUpdateService,
    {
       provide: FirestoreSettingsToken, useValue: {}
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DynamicTabComponent]
})
export class AppModule { }
