import { Component, Input, Output, EventEmitter } from '@angular/core';

// Models
import { Item } from './models/item';
import { List } from '../lists/list';

// Services
import { ListService } from '../lists/services/list.service';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from './services/item.service';

// Components
import { ConfirmModalComponent } from '../shared/components/confirm-modal/confirm-modal.component';

// External
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ModalData } from '../shared/components/confirm-modal/modal-data';
import { ItemDetails } from './models/item-details';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { observable, pipe, Subject } from 'rxjs';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent {
  @Output() updatedList = new EventEmitter<any>();

  public _listId: string;
  @Input()
  set listId(listId: string) {
    this._listId = listId;
  }

  public _listName: string;
  @Input()
  set listName(listName: string) {
    this._listName = listName;
  }

  public _listAdvancedSetting: boolean;
  @Input()
  set listAdvancedSetting(listAdvancedSetting: boolean) {
    this._listAdvancedSetting = listAdvancedSetting;
  }

  public _list: List;
  @Input()
  set list(list: List) {
    this._list = list;
  }

  public _items: Item[];
  @Input()
  set items(items: Item[]) {
    this._items = items;
    if (items && items.length <= this.minNumberOfRows) {
      this.paperLines = Array(this.minNumberOfRows - items.length).fill(0).map((x, i) => {
        return i;
      });
    } else {
      this.paperLines = Array(0).fill(0).map((x, i) => {
        return i;
      });
    }
  }

  newItemName = '';
  paperLines: number[];
  step: any;
  quillEditorText = '';
  itemDetails: any;
  color = 'accent';
  disabled = false;
  share = false;
  private minNumberOfRows = 8;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private listService: ListService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private itemService: ItemService) {
  }

  setStep(index: string) {
    this.step = index;
    this.getItemDetails(index);
  }

  nextStep(stepId: string) {
    const elementPos = this._items.map((x) => x.id).indexOf(stepId);
    const objectFound = this._items[elementPos + 1];
    this.step = objectFound != null ? objectFound.id : this._items[0].id;
  }

  prevStep(stepId: string) {
    const elementPos = this._items.map((x) => x.id).indexOf(stepId);
    const objectFound = this._items[elementPos - 1];
    this.step = objectFound != null ? objectFound.id : this._items[this._items.length - 1].id;
  }

  getItemDetails(itemId: string) {
    this.itemService.getItemDetails(itemId)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.quillEditorText = this.itemDetails.length > 0 ? this.itemDetails[0].html : '';
        })
      )
      .subscribe(data => {
        this.itemDetails = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as ItemDetails;
        });
      });
  }

  saveItemDetails(itemId: string) {
    const itemDetails = {
      html: this.quillEditorText,
      itemId
    } as ItemDetails;
    this.itemService.saveItemDetails(itemDetails);
  }


  onPressEnterUpdateListName(event) {
    if (event.key === 'Enter' && event.target.value !== '') {
      this.updateListObject(event)
        .finally(() => {
          this.toastr.success('List name has been updated.');
        })
        .then(res =>
          this.emitUpdatedList(event)
        );
    }
  }

  updateListObject(event: any) {
    return new Promise((resolve, reject) => {
      this._list.name = event.target.value;
      this._list.id = this._listId;
      this._list.isAdvanced = this._listAdvancedSetting;
      this._list.nameInsensitive = event.target.value.toLowerCase();
      this.listService.updateList(this._list);
      resolve();
    });
  }

  emitUpdatedList(event: any): any {
    this.updatedList.emit(this._list);
  }

  updateIsAdvanced() {
    this.listService.updateListAdvanceTrigger(this._listId, this._listAdvancedSetting);
  }

  deleteList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'Confirm List Removal',
      yesText: 'Yes',
      noText: 'Cancel',
      bodyText: 'Are you sure you want to remove this list?'
    } as ModalData;

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.listService.deleteList(this._listId, this._items);
      }
    });
  }
}
