/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';

@Component({
 selector: 'app-dynamic-tab',
 templateUrl: './dynamic-tab.component.html',
 styleUrls: ['./dynamic-tab.component.scss']
})
export class DynamicTabComponent {
    @Input('tabTitle') title: string;
    @Input() active = false;
    @Input() isCloseable = false;
    @Input() template: any;
    @Input() dataContext: any;
}
