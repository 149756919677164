import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { User } from '../auth/user.model';

import { AuthService } from '../auth/services/auth.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input() isNew = false;

  public edit = false;
  loadedUser: User;

  constructor(
    public auth: AuthService,
    public userService: UserService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.auth.user$
      .subscribe((user: User) => {
        this.loadedUser = ({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          publicName: user.publicName,
          firstName: user.firstName,
          lastName: user.lastName,
          dateOfBirth: '',
          phoneNumber: '',
          photoURL: user.photoURL !== '' ? user.photoURL : '',
          providerId: user.providerId,
          isRegistered: user.isRegistered,
          isPublic: user.isPublic
        }) as User;
      });
  }


  updateUserRegistration() {
    this.userService.updateUser(this.loadedUser);
    this.toastr.success('Your profile has been udpated.');
  }
}
