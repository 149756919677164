import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CheckForUpdateService } from './shared/services/checkforupdate.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  updateAvailable = false;
  ToastrModule: any;
  constructor(
    private updates: SwUpdate,
    private checkForUpdateService: CheckForUpdateService,
    private toastr: ToastrService) {
    this.updates.available.subscribe((event) => {
      this.updateAvailable = true;
      this.toastr.success('You have an update available');
    });
  }
}
