import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthProvider } from 'ngx-auth-firebaseui';

import { AuthService } from '../auth/services/auth.service';

import { User } from '../auth/user.model';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  providers = AuthProvider;
  user: User;

  constructor(
    public auth: AuthService,
    public router: Router
  ) { }

  navigate() {
    this.auth.user$
    .subscribe(user => {
      if (user.isRegistered) {
        this.router.navigate(['/lists']);
      } else {
        this.router.navigate(['/newUserRegistration']);
      }
    });
  }
}
