import { NgModule } from '@angular/core';
import { MatDialogModule, MatCardModule, MatButtonModule } from '@angular/material';

// Components
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
      ConfirmModalComponent
  ],
  imports: [
    MatCardModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    ConfirmModalComponent,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [],
  entryComponents: [
    ConfirmModalComponent
  ]
})
export class SharedModule { }
